.Attribution {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 2px;
    z-index: 10;
}

.Attribution a,
.Attribution span {
    color: white;
    text-decoration: none;
    margin: 0 2px 4px;
}

.Attribution span {
    pointer-events: none;
}

.Attribution a:hover {
    color: rgb(53, 175, 109);
}
.BackgroundWrapper {
    background-color: black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    padding-top: 30%;
}

.Background p {
    position: relative;
    padding: 5px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
}

.BearingIndicator {
    bottom: 28px;
    height: 66px;
    pointer-events: none;
    position: absolute;
    right: 11px;
    width: 56px;
    z-index: 10;
}

.BearingIndicatorBackground {
    opacity: 0.5;
    pointer-events: auto;
}

.BearingIndicatorBackgroundRectangle {
    background: #242528;
    height: 12px;
    left: 23px;
    position: absolute;
    top: 0;
    width: 10px;
}

.BearingIndicatorBackgroundCircle {
    background: #242528;
    border-radius: 50%;
    bottom: 0;
    height: 56px;
    position: absolute;
    width: 56px;
}

.BearingIndicatorNorth {
    background: white;
    height: 6px;
    left: 27px;
    position: absolute;
    top: 4px;
    width: 2px;
}
.Cover {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    position: absolute;
    overflow: hidden;
}

.CoverBackground {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    margin-left: -3px;
    right: 0;
    top: 0;
    transform: translateZ(0);
    transition: transform 5s ease-out, background-image .2s ease-in-out;
    z-index: -10;
    position: absolute;
    margin-right: -3px;
}

.Cover:hover .CoverBackground {
    transform: scale(1.1);
}

.Cover.CoverLoading .CoverBackground{
    transform: scale(1);
    transition: transform .4s ease-out;
}

.CoverBackgroundGradient {
    background: linear-gradient(-180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    bottom: 0;
    height: 110px;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    transition: opacity .2s ease-in-out;
    z-index: 1;
}

.CoverLogo {
    background-size: cover;
    background: url(./cover-logo.svg);
    bottom: 30px;
    height: 32px;
    left: 30px;
    position: absolute;
    transition: opacity .2s ease-in-out;
    width: 128px;
    z-index: 300;
}

@media screen and (max-width: 560px) {
  .CoverLogo {
      display: none;
  }
}

.Cover.CoverDone > .CoverBackground {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
}

.CoverDone {
    visibility: hidden;
    opacity: 0;
}

.CoverButton {
    background-color: transparent;
    border-radius: 999px;
    border: 1px solid white;
    bottom: 30px;
    color: white;
    font-size: 14px;
    font-weight: 300;
    height: 34px;
    left: 50%;
    letter-spacing: 1px;
    margin-left: -70px;
    padding: 5px 30px 7px;
    position: absolute;
    transition: all .2s ease-in-out;
    width: 140px;
}

.CoverButton:hover {
    background-color: white;
    color: #5D6671;
    cursor: pointer;
}

.CoverButton:focus {
    outline: 0;
}

.CoverButtonIcon {
    background-size: contain;
    background: url(./pano.svg);
    display: inline-block;
    height: 60px;
    width: 60px;
}

.Spinner {
  animation: rotate 3s linear infinite;
  background-image: url('./spinner.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 32px;
  left: 50%;
  margin-left: -16px;
  margin-top: -16px;
  position: absolute;
  top: 50%;
  width: 32px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.Cover.CoverLoading .CoverButton,
.Cover.CoverLoading .CoverLogo,
.Cover.CoverLoading .CoverBackgroundGradient {
    opacity: 0;
}
.Debug {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30em;
    background-color: lime;
    font-size: 12px;
    padding: 1em;
    font-family: Input, Menlo, monospace;
    z-index: 50;
}

.DebugButton {
    background-color: lime;
    color: white;
    border: 1px solid white;
    border-radius: 2px;
    cursor: pointer;
    cursor: hand;
    z-index: 50;
}

.DebugButtonFixed {
    position: absolute;
    bottom: 2px;
    left: 2px;
}
.DirectionsPerspective {
    display: block;
    position: absolute;
    pointer-events: none;
    transform-style: preserve-3d;
    z-index: 10;
}

.DirectionsCircle,
.DirectionsCircleSequence,
.DirectionsCircleDisabled,
.DirectionsCircleHighlight,
.DirectionsCircleSequenceHighlight {
    position: absolute;
    border-radius: 50%;
}

.DirectionsCircle,
.DirectionsCircleSequence,
.DirectionsCircleHighlight,
.DirectionsCircleSequenceHighlight {
    pointer-events: auto;
}

.DirectionsCircle:hover,
.DirectionsCircleSequence:hover,
.DirectionsCircleHighlight:hover,
.DirectionsCircleSequenceHighlight:hover {
    cursor: pointer;
    cursor: hand;
}

.DirectionsCircle,
.DirectionsCircleSequence {
    background: rgba(0, 0, 0, 0);
}

.DirectionsCircleDisabled {
    background: rgba(0, 0, 0, 0);
    z-index: -1;
}

.DirectionsCircleHighlight,
.DirectionsCircleSequenceHighlight {
    background: rgba(0, 0, 0, 0.4);
}

.DirectionsArrowStep,
.DirectionsArrowPano,
.DirectionsArrowDisabled {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    height: 44.0775%;
    pointer-events: none;
}

.DirectionsArrowStep,
.DirectionsArrowDisabled {
    background: url(./pointer-white.svg);
    background-repeat: no-repeat;
}

.DirectionsArrowDisabled {
    opacity: 0.2;
    z-index: -1;
}

.DirectionsArrowPano {
    background: url(./pointer-wheat.svg);
    background-repeat: no-repeat;
}

.TurnCircle,
.TurnCircleSequence,
.TurnCircleHighlight,
.TurnCircleSequenceHighlight {
    position: absolute;
    border-radius: 50%;
    pointer-events: auto;
}

.TurnCircle,
.TurnCircleSequence {
    background: rgba(0, 0, 0, 0);
}

.TurnCircleHighlight,
.TurnCircleSequenceHighlight {
    background: rgba(0, 0, 0, 0.4);
}

.TurnCircle:hover,
.TurnCircleSequence:hover,
.TurnCircleHighlight:hover,
.TurnCircleSequenceHighlight:hover {
    cursor: pointer;
    cursor: hand;
}

.TurnLeft,
.TurnRight,
.TurnAround {
    position: absolute;
    top: 50%;
    pointer-events: none;
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.8));
}

.TurnLeft,
.TurnRight {
    width: 58.794%;
    height: 70%;
}

.TurnAround {
    left: 50%;
    width: 75%;
    height: 63.2475%;
}

.TurnLeft {
    left: 45%;
    background: url(./turn.svg);
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
}

.TurnRight {
    left: 55%;
    background: url(./turn.svg);
    background-repeat: no-repeat;
    transform: translate(-50%, -50%) scaleX(-1);
}

.TurnAround {
    background: url(./turn-around.svg);
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
}

@media (hover: hover) {
    .DirectionsCircle:hover,
    .DirectionsCircleSequence:hover {
        background: rgba(0, 0, 0, 0.4);
    }

    .TurnCircle:hover,
    .TurnCircleSequence:hover {
        background: rgba(0, 0, 0, 0.4);
    }
}
.Loading {
    width: 100%;
    height: 2px;
    z-index: 20;
    position: absolute;
    top: 0;
}

.LoadingBar {
    height: 4px;
    position: relative;
    background-color: rgba(53, 175, 109, 0.8);
    transition: opacity 100ms;
}
.NavigationSequence,
.NavigationSpatial {
    position: absolute;
    pointer-events: none;
    z-index: 10;
}

.NavigationSequence {
    top: 12px;
    left: 50%;
    margin-left: -40px;
}

.NavigationSpatial {
    bottom: 18px;
    left: 50%;
    margin-left: -100px;
}

.NavigationSpatialBottom {
    margin-left: 40px;
}

.Direction {
    width: 36px;
    height: 36px;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: auto;
    margin: 0px 2px;
    cursor: pointer;
    display: inline-block;
    filter: drop-shadow(0 2px 0 rgba(0, 0, 0, 1));
}

.DirectionPrev,
.DirectionLeft {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgwcHgiIGhlaWdodD0iMTgwcHgiIHZpZXdCb3g9IjAgMCAxODAgMTgwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgdHJhbnNmb3JtPSJyb3RhdGUoLTkwIDkwLDkwKSI+PGcgaWQ9ImFycm93LXVwIiBmaWxsPSIjRkZGRkZGIj48cGF0aCBkPSJNODguODg0MzgxNyw0NS43Mzc4ODA2IEM4OC4yOTk4ODUzLDQ1Ljk0OTc5MTYgODcuNzUxNTY5Miw0Ni4yODk5Mjk5IDg3LjI4MzA4NTMsNDYuNzU4NDEzOCBMMzEuMzYzMzk0NywxMDIuNjc4MTA0IEMyOS42NDYwNjE0LDEwNC4zOTU0MzggMjkuNjUxODM2MywxMDcuMTcwNDk4IDMxLjM2NzAwMzEsMTA4Ljg4NTY2NCBMNDAuNjg2NzQ1MywxMTguMjA1NDA3IEM0Mi40MDAzOTY3LDExOS45MTkwNTggNDUuMTgxMTMxMywxMTkuOTIyMTg5IDQ2Ljg5NDMwNTIsMTE4LjIwOTAxNSBMOTAuODAwOTI3Niw3NC4zMDIzOTI2IEwxMzQuNDUwNTUsMTE3Ljk1MjAxNSBDMTM2LjE2Nzg4MywxMTkuNjY5MzQ5IDEzOC45NDI5NDMsMTE5LjY2MzU3NCAxNDAuNjU4MTEsMTE3Ljk0ODQwNyBMMTQ5Ljk3Nzg1MiwxMDguNjI4NjY1IEMxNTEuNjkxNTA0LDEwNi45MTUwMTMgMTUxLjY5NDYzNSwxMDQuMTM0Mjc5IDE0OS45ODE0NjEsMTAyLjQyMTEwNSBMOTQuMDYxNzcwMSw0Ni41MDE0MTQxIEM5Mi42NTczMTA5LDQ1LjA5Njk1NDkgOTAuNTQ1NDIzMSw0NC44NDQ5NDQ1IDg4Ljg4NDM4MTcsNDUuNzM3ODgwNiBMODguODg0MzgxNyw0NS43Mzc4ODA2IFoiIGlkPSJBcnJvdyIgPjwvcGF0aD48cGF0aCBkPSJNOTAsMTcwLjEzODgxMiBDMTM0LjI1OTQ0NCwxNzAuMTM4ODEyIDE3MC4xMzg4MTIsMTM0LjI1OTQ0NCAxNzAuMTM4ODEyLDkwIEMxNzAuMTM4ODEyLDQ1Ljc0MDU1NjIgMTM0LjI1OTQ0NCw5Ljg2MTE4Nzg1IDkwLDkuODYxMTg3ODUgQzQ1Ljc0MDU1NjIsOS44NjExODc4NSA5Ljg2MTE4Nzg1LDQ1Ljc0MDU1NjIgOS44NjExODc4NSw5MCBDOS44NjExODc4NSwxMzQuMjU5NDQ0IDQ1Ljc0MDU1NjIsMTcwLjEzODgxMiA5MCwxNzAuMTM4ODEyIEw5MCwxNzAuMTM4ODEyIEw5MCwxNzAuMTM4ODEyIFogTTAsOTAgQzAsNDAuMjk0MzcyNSA0MC4yOTQzNzI1LDAgOTAsMCBDMTM5LjcwNTYyNywwIDE4MCw0MC4yOTQzNzI1IDE4MCw5MCBDMTgwLDEzOS43MDU2MjcgMTM5LjcwNTYyNywxODAgOTAsMTgwIEM0MC4yOTQzNzI1LDE4MCAwLDEzOS43MDU2MjcgMCw5MCBaIiBpZD0iQ2lyY2xlIj48L3BhdGg+PC9nPjwvZz48L3N2Zz4=");
}

.DirectionNext,
.DirectionRight {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgwcHgiIGhlaWdodD0iMTgwcHgiIHZpZXdCb3g9IjAgMCAxODAgMTgwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgdHJhbnNmb3JtPSJyb3RhdGUoOTAgOTAsOTApIj48ZyBpZD0iYXJyb3ctdXAiIGZpbGw9IiNGRkZGRkYiPjxwYXRoIGQ9Ik04OC44ODQzODE3LDQ1LjczNzg4MDYgQzg4LjI5OTg4NTMsNDUuOTQ5NzkxNiA4Ny43NTE1NjkyLDQ2LjI4OTkyOTkgODcuMjgzMDg1Myw0Ni43NTg0MTM4IEwzMS4zNjMzOTQ3LDEwMi42NzgxMDQgQzI5LjY0NjA2MTQsMTA0LjM5NTQzOCAyOS42NTE4MzYzLDEwNy4xNzA0OTggMzEuMzY3MDAzMSwxMDguODg1NjY0IEw0MC42ODY3NDUzLDExOC4yMDU0MDcgQzQyLjQwMDM5NjcsMTE5LjkxOTA1OCA0NS4xODExMzEzLDExOS45MjIxODkgNDYuODk0MzA1MiwxMTguMjA5MDE1IEw5MC44MDA5Mjc2LDc0LjMwMjM5MjYgTDEzNC40NTA1NSwxMTcuOTUyMDE1IEMxMzYuMTY3ODgzLDExOS42NjkzNDkgMTM4Ljk0Mjk0MywxMTkuNjYzNTc0IDE0MC42NTgxMSwxMTcuOTQ4NDA3IEwxNDkuOTc3ODUyLDEwOC42Mjg2NjUgQzE1MS42OTE1MDQsMTA2LjkxNTAxMyAxNTEuNjk0NjM1LDEwNC4xMzQyNzkgMTQ5Ljk4MTQ2MSwxMDIuNDIxMTA1IEw5NC4wNjE3NzAxLDQ2LjUwMTQxNDEgQzkyLjY1NzMxMDksNDUuMDk2OTU0OSA5MC41NDU0MjMxLDQ0Ljg0NDk0NDUgODguODg0MzgxNyw0NS43Mzc4ODA2IEw4OC44ODQzODE3LDQ1LjczNzg4MDYgWiIgaWQ9IkFycm93IiA+PC9wYXRoPjxwYXRoIGQ9Ik05MCwxNzAuMTM4ODEyIEMxMzQuMjU5NDQ0LDE3MC4xMzg4MTIgMTcwLjEzODgxMiwxMzQuMjU5NDQ0IDE3MC4xMzg4MTIsOTAgQzE3MC4xMzg4MTIsNDUuNzQwNTU2MiAxMzQuMjU5NDQ0LDkuODYxMTg3ODUgOTAsOS44NjExODc4NSBDNDUuNzQwNTU2Miw5Ljg2MTE4Nzg1IDkuODYxMTg3ODUsNDUuNzQwNTU2MiA5Ljg2MTE4Nzg1LDkwIEM5Ljg2MTE4Nzg1LDEzNC4yNTk0NDQgNDUuNzQwNTU2MiwxNzAuMTM4ODEyIDkwLDE3MC4xMzg4MTIgTDkwLDE3MC4xMzg4MTIgTDkwLDE3MC4xMzg4MTIgWiBNMCw5MCBDMCw0MC4yOTQzNzI1IDQwLjI5NDM3MjUsMCA5MCwwIEMxMzkuNzA1NjI3LDAgMTgwLDQwLjI5NDM3MjUgMTgwLDkwIEMxODAsMTM5LjcwNTYyNyAxMzkuNzA1NjI3LDE4MCA5MCwxODAgQzQwLjI5NDM3MjUsMTgwIDAsMTM5LjcwNTYyNyAwLDkwIFoiIGlkPSJDaXJjbGUiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg==");
}

.DirectionForward {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgwcHgiIGhlaWdodD0iMTgwcHgiIHZpZXdCb3g9IjAgMCAxODAgMTgwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgdHJhbnNmb3JtPSJyb3RhdGUoMCA5MCw5MCkiPjxnIGlkPSJhcnJvdy11cCIgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTg4Ljg4NDM4MTcsNDUuNzM3ODgwNiBDODguMjk5ODg1Myw0NS45NDk3OTE2IDg3Ljc1MTU2OTIsNDYuMjg5OTI5OSA4Ny4yODMwODUzLDQ2Ljc1ODQxMzggTDMxLjM2MzM5NDcsMTAyLjY3ODEwNCBDMjkuNjQ2MDYxNCwxMDQuMzk1NDM4IDI5LjY1MTgzNjMsMTA3LjE3MDQ5OCAzMS4zNjcwMDMxLDEwOC44ODU2NjQgTDQwLjY4Njc0NTMsMTE4LjIwNTQwNyBDNDIuNDAwMzk2NywxMTkuOTE5MDU4IDQ1LjE4MTEzMTMsMTE5LjkyMjE4OSA0Ni44OTQzMDUyLDExOC4yMDkwMTUgTDkwLjgwMDkyNzYsNzQuMzAyMzkyNiBMMTM0LjQ1MDU1LDExNy45NTIwMTUgQzEzNi4xNjc4ODMsMTE5LjY2OTM0OSAxMzguOTQyOTQzLDExOS42NjM1NzQgMTQwLjY1ODExLDExNy45NDg0MDcgTDE0OS45Nzc4NTIsMTA4LjYyODY2NSBDMTUxLjY5MTUwNCwxMDYuOTE1MDEzIDE1MS42OTQ2MzUsMTA0LjEzNDI3OSAxNDkuOTgxNDYxLDEwMi40MjExMDUgTDk0LjA2MTc3MDEsNDYuNTAxNDE0MSBDOTIuNjU3MzEwOSw0NS4wOTY5NTQ5IDkwLjU0NTQyMzEsNDQuODQ0OTQ0NSA4OC44ODQzODE3LDQ1LjczNzg4MDYgTDg4Ljg4NDM4MTcsNDUuNzM3ODgwNiBaIiBpZD0iQXJyb3ciID48L3BhdGg+PHBhdGggZD0iTTkwLDE3MC4xMzg4MTIgQzEzNC4yNTk0NDQsMTcwLjEzODgxMiAxNzAuMTM4ODEyLDEzNC4yNTk0NDQgMTcwLjEzODgxMiw5MCBDMTcwLjEzODgxMiw0NS43NDA1NTYyIDEzNC4yNTk0NDQsOS44NjExODc4NSA5MCw5Ljg2MTE4Nzg1IEM0NS43NDA1NTYyLDkuODYxMTg3ODUgOS44NjExODc4NSw0NS43NDA1NTYyIDkuODYxMTg3ODUsOTAgQzkuODYxMTg3ODUsMTM0LjI1OTQ0NCA0NS43NDA1NTYyLDE3MC4xMzg4MTIgOTAsMTcwLjEzODgxMiBMOTAsMTcwLjEzODgxMiBMOTAsMTcwLjEzODgxMiBaIE0wLDkwIEMwLDQwLjI5NDM3MjUgNDAuMjk0MzcyNSwwIDkwLDAgQzEzOS43MDU2MjcsMCAxODAsNDAuMjk0MzcyNSAxODAsOTAgQzE4MCwxMzkuNzA1NjI3IDEzOS43MDU2MjcsMTgwIDkwLDE4MCBDNDAuMjk0MzcyNSwxODAgMCwxMzkuNzA1NjI3IDAsOTAgWiIgaWQ9IkNpcmNsZSI+PC9wYXRoPjwvZz48L2c+PC9zdmc+");
}

.DirectionBackward {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgwcHgiIGhlaWdodD0iMTgwcHgiIHZpZXdCb3g9IjAgMCAxODAgMTgwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgdHJhbnNmb3JtPSJyb3RhdGUoMTgwIDkwLDkwKSI+PGcgaWQ9ImFycm93LXVwIiBmaWxsPSIjRkZGRkZGIj48cGF0aCBkPSJNODguODg0MzgxNyw0NS43Mzc4ODA2IEM4OC4yOTk4ODUzLDQ1Ljk0OTc5MTYgODcuNzUxNTY5Miw0Ni4yODk5Mjk5IDg3LjI4MzA4NTMsNDYuNzU4NDEzOCBMMzEuMzYzMzk0NywxMDIuNjc4MTA0IEMyOS42NDYwNjE0LDEwNC4zOTU0MzggMjkuNjUxODM2MywxMDcuMTcwNDk4IDMxLjM2NzAwMzEsMTA4Ljg4NTY2NCBMNDAuNjg2NzQ1MywxMTguMjA1NDA3IEM0Mi40MDAzOTY3LDExOS45MTkwNTggNDUuMTgxMTMxMywxMTkuOTIyMTg5IDQ2Ljg5NDMwNTIsMTE4LjIwOTAxNSBMOTAuODAwOTI3Niw3NC4zMDIzOTI2IEwxMzQuNDUwNTUsMTE3Ljk1MjAxNSBDMTM2LjE2Nzg4MywxMTkuNjY5MzQ5IDEzOC45NDI5NDMsMTE5LjY2MzU3NCAxNDAuNjU4MTEsMTE3Ljk0ODQwNyBMMTQ5Ljk3Nzg1MiwxMDguNjI4NjY1IEMxNTEuNjkxNTA0LDEwNi45MTUwMTMgMTUxLjY5NDYzNSwxMDQuMTM0Mjc5IDE0OS45ODE0NjEsMTAyLjQyMTEwNSBMOTQuMDYxNzcwMSw0Ni41MDE0MTQxIEM5Mi42NTczMTA5LDQ1LjA5Njk1NDkgOTAuNTQ1NDIzMSw0NC44NDQ5NDQ1IDg4Ljg4NDM4MTcsNDUuNzM3ODgwNiBMODguODg0MzgxNyw0NS43Mzc4ODA2IFoiIGlkPSJBcnJvdyIgPjwvcGF0aD48cGF0aCBkPSJNOTAsMTcwLjEzODgxMiBDMTM0LjI1OTQ0NCwxNzAuMTM4ODEyIDE3MC4xMzg4MTIsMTM0LjI1OTQ0NCAxNzAuMTM4ODEyLDkwIEMxNzAuMTM4ODEyLDQ1Ljc0MDU1NjIgMTM0LjI1OTQ0NCw5Ljg2MTE4Nzg1IDkwLDkuODYxMTg3ODUgQzQ1Ljc0MDU1NjIsOS44NjExODc4NSA5Ljg2MTE4Nzg1LDQ1Ljc0MDU1NjIgOS44NjExODc4NSw5MCBDOS44NjExODc4NSwxMzQuMjU5NDQ0IDQ1Ljc0MDU1NjIsMTcwLjEzODgxMiA5MCwxNzAuMTM4ODEyIEw5MCwxNzAuMTM4ODEyIEw5MCwxNzAuMTM4ODEyIFogTTAsOTAgQzAsNDAuMjk0MzcyNSA0MC4yOTQzNzI1LDAgOTAsMCBDMTM5LjcwNTYyNywwIDE4MCw0MC4yOTQzNzI1IDE4MCw5MCBDMTgwLDEzOS43MDU2MjcgMTM5LjcwNTYyNywxODAgOTAsMTgwIEM0MC4yOTQzNzI1LDE4MCAwLDEzOS43MDU2MjcgMCw5MCBaIiBpZD0iQ2lyY2xlIj48L3BhdGg+PC9nPjwvZz48L3N2Zz4=");
}

.DirectionTurnleft {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgwcHgiIGhlaWdodD0iMTgwcHgiIHZpZXdCb3g9IjAgMCAxODAgMTgwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgdHJhbnNmb3JtPSJyb3RhdGUoLTQ1IDkwLDkwKSI+PGcgaWQ9ImFycm93LXVwIiBmaWxsPSIjRkZGRkZGIj48cGF0aCBkPSJNODguODg0MzgxNyw0NS43Mzc4ODA2IEM4OC4yOTk4ODUzLDQ1Ljk0OTc5MTYgODcuNzUxNTY5Miw0Ni4yODk5Mjk5IDg3LjI4MzA4NTMsNDYuNzU4NDEzOCBMMzEuMzYzMzk0NywxMDIuNjc4MTA0IEMyOS42NDYwNjE0LDEwNC4zOTU0MzggMjkuNjUxODM2MywxMDcuMTcwNDk4IDMxLjM2NzAwMzEsMTA4Ljg4NTY2NCBMNDAuNjg2NzQ1MywxMTguMjA1NDA3IEM0Mi40MDAzOTY3LDExOS45MTkwNTggNDUuMTgxMTMxMywxMTkuOTIyMTg5IDQ2Ljg5NDMwNTIsMTE4LjIwOTAxNSBMOTAuODAwOTI3Niw3NC4zMDIzOTI2IEwxMzQuNDUwNTUsMTE3Ljk1MjAxNSBDMTM2LjE2Nzg4MywxMTkuNjY5MzQ5IDEzOC45NDI5NDMsMTE5LjY2MzU3NCAxNDAuNjU4MTEsMTE3Ljk0ODQwNyBMMTQ5Ljk3Nzg1MiwxMDguNjI4NjY1IEMxNTEuNjkxNTA0LDEwNi45MTUwMTMgMTUxLjY5NDYzNSwxMDQuMTM0Mjc5IDE0OS45ODE0NjEsMTAyLjQyMTEwNSBMOTQuMDYxNzcwMSw0Ni41MDE0MTQxIEM5Mi42NTczMTA5LDQ1LjA5Njk1NDkgOTAuNTQ1NDIzMSw0NC44NDQ5NDQ1IDg4Ljg4NDM4MTcsNDUuNzM3ODgwNiBMODguODg0MzgxNyw0NS43Mzc4ODA2IFoiIGlkPSJBcnJvdyIgPjwvcGF0aD48cGF0aCBkPSJNOTAsMTcwLjEzODgxMiBDMTM0LjI1OTQ0NCwxNzAuMTM4ODEyIDE3MC4xMzg4MTIsMTM0LjI1OTQ0NCAxNzAuMTM4ODEyLDkwIEMxNzAuMTM4ODEyLDQ1Ljc0MDU1NjIgMTM0LjI1OTQ0NCw5Ljg2MTE4Nzg1IDkwLDkuODYxMTg3ODUgQzQ1Ljc0MDU1NjIsOS44NjExODc4NSA5Ljg2MTE4Nzg1LDQ1Ljc0MDU1NjIgOS44NjExODc4NSw5MCBDOS44NjExODc4NSwxMzQuMjU5NDQ0IDQ1Ljc0MDU1NjIsMTcwLjEzODgxMiA5MCwxNzAuMTM4ODEyIEw5MCwxNzAuMTM4ODEyIEw5MCwxNzAuMTM4ODEyIFogTTAsOTAgQzAsNDAuMjk0MzcyNSA0MC4yOTQzNzI1LDAgOTAsMCBDMTM5LjcwNTYyNywwIDE4MCw0MC4yOTQzNzI1IDE4MCw5MCBDMTgwLDEzOS43MDU2MjcgMTM5LjcwNTYyNywxODAgOTAsMTgwIEM0MC4yOTQzNzI1LDE4MCAwLDEzOS43MDU2MjcgMCw5MCBaIiBpZD0iQ2lyY2xlIj48L3BhdGg+PC9nPjwvZz48L3N2Zz4=");
}

.DirectionTurnright {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgwcHgiIGhlaWdodD0iMTgwcHgiIHZpZXdCb3g9IjAgMCAxODAgMTgwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgdHJhbnNmb3JtPSJyb3RhdGUoNDUgOTAsOTApIj48ZyBpZD0iYXJyb3ctdXAiIGZpbGw9IiNGRkZGRkYiPjxwYXRoIGQ9Ik04OC44ODQzODE3LDQ1LjczNzg4MDYgQzg4LjI5OTg4NTMsNDUuOTQ5NzkxNiA4Ny43NTE1NjkyLDQ2LjI4OTkyOTkgODcuMjgzMDg1Myw0Ni43NTg0MTM4IEwzMS4zNjMzOTQ3LDEwMi42NzgxMDQgQzI5LjY0NjA2MTQsMTA0LjM5NTQzOCAyOS42NTE4MzYzLDEwNy4xNzA0OTggMzEuMzY3MDAzMSwxMDguODg1NjY0IEw0MC42ODY3NDUzLDExOC4yMDU0MDcgQzQyLjQwMDM5NjcsMTE5LjkxOTA1OCA0NS4xODExMzEzLDExOS45MjIxODkgNDYuODk0MzA1MiwxMTguMjA5MDE1IEw5MC44MDA5Mjc2LDc0LjMwMjM5MjYgTDEzNC40NTA1NSwxMTcuOTUyMDE1IEMxMzYuMTY3ODgzLDExOS42NjkzNDkgMTM4Ljk0Mjk0MywxMTkuNjYzNTc0IDE0MC42NTgxMSwxMTcuOTQ4NDA3IEwxNDkuOTc3ODUyLDEwOC42Mjg2NjUgQzE1MS42OTE1MDQsMTA2LjkxNTAxMyAxNTEuNjk0NjM1LDEwNC4xMzQyNzkgMTQ5Ljk4MTQ2MSwxMDIuNDIxMTA1IEw5NC4wNjE3NzAxLDQ2LjUwMTQxNDEgQzkyLjY1NzMxMDksNDUuMDk2OTU0OSA5MC41NDU0MjMxLDQ0Ljg0NDk0NDUgODguODg0MzgxNyw0NS43Mzc4ODA2IEw4OC44ODQzODE3LDQ1LjczNzg4MDYgWiIgaWQ9IkFycm93IiA+PC9wYXRoPjxwYXRoIGQ9Ik05MCwxNzAuMTM4ODEyIEMxMzQuMjU5NDQ0LDE3MC4xMzg4MTIgMTcwLjEzODgxMiwxMzQuMjU5NDQ0IDE3MC4xMzg4MTIsOTAgQzE3MC4xMzg4MTIsNDUuNzQwNTU2MiAxMzQuMjU5NDQ0LDkuODYxMTg3ODUgOTAsOS44NjExODc4NSBDNDUuNzQwNTU2Miw5Ljg2MTE4Nzg1IDkuODYxMTg3ODUsNDUuNzQwNTU2MiA5Ljg2MTE4Nzg1LDkwIEM5Ljg2MTE4Nzg1LDEzNC4yNTk0NDQgNDUuNzQwNTU2MiwxNzAuMTM4ODEyIDkwLDE3MC4xMzg4MTIgTDkwLDE3MC4xMzg4MTIgTDkwLDE3MC4xMzg4MTIgWiBNMCw5MCBDMCw0MC4yOTQzNzI1IDQwLjI5NDM3MjUsMCA5MCwwIEMxMzkuNzA1NjI3LDAgMTgwLDQwLjI5NDM3MjUgMTgwLDkwIEMxODAsMTM5LjcwNTYyNyAxMzkuNzA1NjI3LDE4MCA5MCwxODAgQzQwLjI5NDM3MjUsMTgwIDAsMTM5LjcwNTYyNyAwLDkwIFoiIGlkPSJDaXJjbGUiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg==");
}

.DirectionTurnaround {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgwcHgiIGhlaWdodD0iMTgwcHgiIHZpZXdCb3g9IjAgMCAxODAgMTgwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgdHJhbnNmb3JtPSJyb3RhdGUoLTEzNSA5MCw5MCkiPjxnIGlkPSJhcnJvdy11cCIgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTg4Ljg4NDM4MTcsNDUuNzM3ODgwNiBDODguMjk5ODg1Myw0NS45NDk3OTE2IDg3Ljc1MTU2OTIsNDYuMjg5OTI5OSA4Ny4yODMwODUzLDQ2Ljc1ODQxMzggTDMxLjM2MzM5NDcsMTAyLjY3ODEwNCBDMjkuNjQ2MDYxNCwxMDQuMzk1NDM4IDI5LjY1MTgzNjMsMTA3LjE3MDQ5OCAzMS4zNjcwMDMxLDEwOC44ODU2NjQgTDQwLjY4Njc0NTMsMTE4LjIwNTQwNyBDNDIuNDAwMzk2NywxMTkuOTE5MDU4IDQ1LjE4MTEzMTMsMTE5LjkyMjE4OSA0Ni44OTQzMDUyLDExOC4yMDkwMTUgTDkwLjgwMDkyNzYsNzQuMzAyMzkyNiBMMTM0LjQ1MDU1LDExNy45NTIwMTUgQzEzNi4xNjc4ODMsMTE5LjY2OTM0OSAxMzguOTQyOTQzLDExOS42NjM1NzQgMTQwLjY1ODExLDExNy45NDg0MDcgTDE0OS45Nzc4NTIsMTA4LjYyODY2NSBDMTUxLjY5MTUwNCwxMDYuOTE1MDEzIDE1MS42OTQ2MzUsMTA0LjEzNDI3OSAxNDkuOTgxNDYxLDEwMi40MjExMDUgTDk0LjA2MTc3MDEsNDYuNTAxNDE0MSBDOTIuNjU3MzEwOSw0NS4wOTY5NTQ5IDkwLjU0NTQyMzEsNDQuODQ0OTQ0NSA4OC44ODQzODE3LDQ1LjczNzg4MDYgTDg4Ljg4NDM4MTcsNDUuNzM3ODgwNiBaIiBpZD0iQXJyb3ciID48L3BhdGg+PHBhdGggZD0iTTkwLDE3MC4xMzg4MTIgQzEzNC4yNTk0NDQsMTcwLjEzODgxMiAxNzAuMTM4ODEyLDEzNC4yNTk0NDQgMTcwLjEzODgxMiw5MCBDMTcwLjEzODgxMiw0NS43NDA1NTYyIDEzNC4yNTk0NDQsOS44NjExODc4NSA5MCw5Ljg2MTE4Nzg1IEM0NS43NDA1NTYyLDkuODYxMTg3ODUgOS44NjExODc4NSw0NS43NDA1NTYyIDkuODYxMTg3ODUsOTAgQzkuODYxMTg3ODUsMTM0LjI1OTQ0NCA0NS43NDA1NTYyLDE3MC4xMzg4MTIgOTAsMTcwLjEzODgxMiBMOTAsMTcwLjEzODgxMiBMOTAsMTcwLjEzODgxMiBaIE0wLDkwIEMwLDQwLjI5NDM3MjUgNDAuMjk0MzcyNSwwIDkwLDAgQzEzOS43MDU2MjcsMCAxODAsNDAuMjk0MzcyNSAxODAsOTAgQzE4MCwxMzkuNzA1NjI3IDEzOS43MDU2MjcsMTgwIDkwLDE4MCBDNDAuMjk0MzcyNSwxODAgMCwxMzkuNzA1NjI3IDAsOTAgWiIgaWQ9IkNpcmNsZSI+PC9wYXRoPjwvZz48L2c+PC9zdmc+");
}
.mapillaryjs-popup {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    display: flex;
}

.mapillaryjs-popup-content,
.mapillaryjs-popup-content-clean {
    position: relative;
    pointer-events: auto;
}

.mapillaryjs-popup-content {
    padding: 5px 10px;
    background: white;
    border: 1px solid black;
    border-radius: 2px;
}

.mapillaryjs-popup-float-bottom,
.mapillaryjs-popup-float-bottom-left,
.mapillaryjs-popup-float-bottom-right {
    flex-direction: column;
}

.mapillaryjs-popup-float-top,
.mapillaryjs-popup-float-top-left,
.mapillaryjs-popup-float-top-right {
    flex-direction: column-reverse;
}

.mapillaryjs-popup-float-left {
    flex-direction: row-reverse;
}

.mapillaryjs-popup-float-right {
    flex-direction: row;
}

.mapillaryjs-popup-tip,
.mapillaryjs-popup-tip-inner {
    position: relative;
    z-index: 1;
    width: 0;
    height: 0;
    border: 7px solid transparent;
}

.mapillaryjs-popup-float-top .mapillaryjs-popup-tip {
    align-self: center;
    border-top-color:black;
    border-bottom: none;
}

.mapillaryjs-popup-float-top .mapillaryjs-popup-tip-inner {
    position: relative;
    top: -8px;
    left: -7px;
    border-top-color: white;
    border-bottom: none;
}

.mapillaryjs-popup-float-top-right .mapillaryjs-popup-tip {
    align-self: flex-start;
    border-top-color:black;
    border-bottom: none;
    border-left: none;
}

.mapillaryjs-popup-float-top-right .mapillaryjs-popup-tip-inner {
    position: relative;
    top: -9px;
    left: 1px;
    border-top-color: white;
    border-bottom: none;
    border-left: none;
}

.mapillaryjs-popup-float-top-left .mapillaryjs-popup-tip {
    align-self: flex-end;
    border-top-color:black;
    border-bottom: none;
    border-right: none;
}

.mapillaryjs-popup-float-top-left .mapillaryjs-popup-tip-inner {
    position: relative;
    top: -9px;
    left: -8px;
    border-top-color: white;
    border-bottom: none;
    border-right: none;
}

.mapillaryjs-popup-float-bottom .mapillaryjs-popup-tip {
    align-self: center;
    border-top: none;
    border-bottom-color: black;
}

.mapillaryjs-popup-float-bottom .mapillaryjs-popup-tip-inner {
    position: relative;
    bottom: -1px;
    left: -7px;
    border-top: none;
    border-bottom-color: white;
}

.mapillaryjs-popup-float-bottom-right .mapillaryjs-popup-tip {
    align-self: flex-start;
    border-bottom-color:black;
    border-top: none;
    border-left: none;
}

.mapillaryjs-popup-float-bottom-right .mapillaryjs-popup-tip-inner {
    position: relative;
    bottom: -2px;
    left: 1px;
    border-bottom-color: white;
    border-top: none;
    border-left: none;
}

.mapillaryjs-popup-float-bottom-left .mapillaryjs-popup-tip {
    align-self: flex-end;
    border-bottom-color:black;
    border-top: none;
    border-right: none;
}

.mapillaryjs-popup-float-bottom-left .mapillaryjs-popup-tip-inner {
    position: relative;
    bottom: -2px;
    left: -8px;
    border-bottom-color: white;
    border-top: none;
    border-right: none;
}

.mapillaryjs-popup-float-left .mapillaryjs-popup-tip {
    align-self: center;
    border-left-color: black;
    border-right: none;
}

.mapillaryjs-popup-float-left .mapillaryjs-popup-tip-inner {
    position: relative;
    top: -7px;
    left: -8px;
    border-left-color: white;
    border-right: none;
}

.mapillaryjs-popup-float-right .mapillaryjs-popup-tip {
    align-self: center;
    border-right-color: black;
    border-left: none;
}

.mapillaryjs-popup-float-right .mapillaryjs-popup-tip-inner {
    position: relative;
    top: -7px;
    left: 1px;
    border-right-color: white;
    border-left: none;
}

.mapillaryjs-popup-float-top-left .mapillaryjs-popup-content {
    border-bottom-right-radius: 0;
}

.mapillaryjs-popup-float-top-right .mapillaryjs-popup-content {
    border-bottom-left-radius: 0;
}

.mapillaryjs-popup-float-bottom-left .mapillaryjs-popup-content {
    border-top-right-radius: 0;
}

.mapillaryjs-popup-float-bottom-right .mapillaryjs-popup-content {
    border-top-left-radius: 0;
}
.RouteFrame {
    background-color: rgba(0, 0, 0, 0.5);
    outline: 1px solid rgba(255, 255, 255, 0.5);
    width: 80%;
    height: 40px;
    text-align: center;
    color: white;
    margin: 0 auto;
    position: absolute;
    z-index: 20;
    bottom: 30%;
    left: 10%;
}

.RouteFrame p {
    margin: 0;
    padding: 10px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
}
.SequenceContainer {
    position: absolute;
    left: 50%;
    top: 14px;
    transform: translate(-50%, 0);
    display: flex;
    z-index: 10;
}

.SequencePlay,
.SequencePlayDisabled,
.SequenceStepNext,
.SequenceStepNextDisabled,
.SequenceStepNextHighlight,
.SequenceStepPrev,
.SequenceStepPrevDisabled,
.SequenceStepPrevHighlight {
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: center;
}

.SequencePlay,
.SequencePlayDisabled,
.SequenceStepNext,
.SequenceStepNextDisabled,
.SequenceStepPrev,
.SequenceStepPrevDisabled {
    background: rgba(36, 37, 40, 0.3);
}

.SequenceStepNextHighlight,
.SequenceStepPrevHighlight {
    background: rgba(36, 37, 40, 0.6);
}

.SequenceStepNext,
.SequenceStepNextDisabled,
.SequenceStepNextHighlight {
    order: 2;
}

.SequenceStepPrev,
.SequenceStepPrevDisabled,
.SequenceStepPrevHighlight {
    order: 0;
}

.SequencePlay,
.SequencePlayDisabled {
    order: 1;
}

.SequencePlay:hover,
.SequenceStepNext:hover,
.SequenceStepNextHighlight:hover,
.SequenceStepPrev:hover,
.SequenceStepPrevHighlight:hover {
    cursor: pointer;
    cursor: hand;
}

.SequenceStepNext,
.SequenceStepNextDisabled,
.SequenceStepNextHighlight {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}

.SequenceStepPrev,
.SequenceStepPrevDisabled,
.SequenceStepPrevHighlight {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}

.Play.SequenceComponentIcon,
.PlayDisabled.SequenceComponentIcon {
    background-image: url('./stepper-play.svg');
}

.Stop.SequenceComponentIcon,
.StopDisabled.SequenceComponentIcon {
    background-image: url('./stepper-stop.svg');
}

.SequenceStepPrev .SequenceComponentIcon,
.SequenceStepPrevDisabled .SequenceComponentIcon,
.SequenceStepPrevHighlight .SequenceComponentIcon {
    background-image: url('./stepper-left.svg');
}

.SequenceStepNext .SequenceComponentIcon,
.SequenceStepNextDisabled .SequenceComponentIcon,
.SequenceStepNextHighlight .SequenceComponentIcon {
    background-image: url('./stepper-right.svg');
}

.PlayDisabled.SequenceComponentIcon,
.SequenceStepNextDisabled .SequenceComponentIcon,
.SequenceStepPrevDisabled .SequenceComponentIcon,
.StopDisabled.SequenceComponentIcon {
    opacity: 0.35;
}

.SequenceComponentIcon {
    background-size: contain;
    background-repeat: no-repeat;
    width: 50%;
    padding-bottom: 50%;
}

@media (hover: hover) {
    .SequencePlay:hover,
    .SequenceStepPrev:hover,
    .SequenceStepNext:hover {
        background: rgba(36, 37, 40, 0.6);
    }
}
.SliderWrapper {
    text-align: center;
    position: absolute;
    bottom: 40px;
    left: 0;
    pointer-events: none;
    right: 0;
    z-index: 20;
}

.SliderControl {
    max-width: 60%;
    padding: 4px;
    box-sizing: border-box;
    display: block;
    width: 100%;
    margin: 0 auto;
    marginTop: 10px;
    cursor: pointer;
    color: inherit;
    background-color: rgba(255, 255, 255, 0.20);
    background-clip: 'content-box';
    border-radius: 999px;
    -webkit-appearance: none;
    appearance: none;
    pointer-events: auto;
}

.SliderControl::-webkit-slider-thumb {
    width: 22px;
    height: 22px;
    background: #474A4E;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
    border: 2px solid white;
    border-radius: 999px;
    -webkit-appearance: none;
}

.SliderControl::-moz-range-thumb {
    width: 22px;
    height: 22px;
    background: #474A4E;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
    border: 2px solid white;
    border-radius: 999px;
    appearance: none;
}

.SliderControl::-ms-track {
    background-color: transparent;
    border-color: transparent;
    border-radius: 999px;
    box-sizing: border-box;
    color: transparent;    
    cursor: pointer;
    display: block;
    height: 30px;
    margin: 0 auto;
    max-width: 60%;
    padding: 4px;
    pointer-events: auto;    
    width: 100%;
}

.SliderControl::-ms-thumb {
    background: #474A4E;
    border: 2px solid white;
    border-radius: 999px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
    height: 22px;
    width: 22px;
}

.SliderControl::-ms-fill-lower,
.SliderControl::-ms-fill-upper,
.SliderControl::-ms-tooltip {
    display: none;
}

.SliderControl:focus {
    outline: none;
}
.mapillary-js.component-tag-create .mapillary-js-interactive {
    cursor: crosshair;
}

.TagCompleter,
.TagInteractor,
.TagMover,
.TagResizer,
.TagSpotInteractor,
.TagSymbol,
.TagVertex {
    position: absolute;
    left: 0;
    top: 0;
}

.TagSymbol {
    cursor: pointer;
    pointer-events: auto;
}

.TagMover,
.TagResizer {
    pointer-events: auto;
}

.TagMover {
    border-radius: 7px;
    width: 14px;
    height: 14px;
    opacity: 0.2;
    cursor: move;
}

.TagMover:hover {
    opacity: 0.5;
}

.TagResizer {
    border-radius: 7px;
    width: 14px;
    height: 14px;
    opacity: 0;
    cursor: crosshair;
}

.TagResizer:hover {
    opacity: 0.3;
}

.TagVertex {
    pointer-events: none;
    border-radius: 3px;
    width: 6px;
    height: 6px;
}

.TagInteractor,
.TagCompleter,
.TagSpotInteractor {
    pointer-events: auto;
    border-radius: 7px;
    width: 14px;
    height: 14px;
    opacity: 0;
}

.TagInteractor,
.TagCompleter {
    cursor: pointer;
}

.TagSpotInteractor {
    cursor: move;
}

.TagCompleter {
    background: limegreen;
}

.TagInteractor {
    background: orangered;
}

.TagCompleter:hover,
.TagInteractor:hover {
    opacity: 0.6;
}

.TagSpotInteractor:hover {
    opacity: 0.3;
}
.mapillary-js {
    background-color: #202020;
    position: relative;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.mapillary-js-interactive {
     cursor: grab;
}

.mapillary-js-interactive:active {
     cursor: grabbing;
}

.mapillary-js .mapillary-js-dom {
    user-select: none;
    position: relative;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.mapillary-js-dom .domRenderer {
    pointer-events: auto;
}
